import { produce } from 'immer'
import { Reducer } from 'redux'

import { AuthActionTypes, SIGN_IN_SUCCESS } from '../auth/types'
import {
  CLEAR_CURRENT_USER,
  REMOVE_SAVED_USER,
  UPDATE_CURRENT_USER,
  UserActionTypes,
  UserState,
} from './types'

const INITIAL_STATE = {
  currentUser: null,
  usersAccounts: [],
}

const user: Reducer<UserState, AuthActionTypes | UserActionTypes> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SIGN_IN_SUCCESS: {
        const currentUser = action.payload.user
        draft.currentUser = currentUser

        const { uid, name, imageUrl } = currentUser

        const isAlreadySaved = draft.usersAccounts.find(
          (acc) => acc.uid === uid,
        )

        if (!isAlreadySaved) {
          draft.usersAccounts.push({
            uid,
            name,
            imageUrl,
          })
        }

        break
      }

      case UPDATE_CURRENT_USER: {
        draft.currentUser = {
          ...draft.currentUser,
          ...(action.payload as any),
        }
        break
      }

      case CLEAR_CURRENT_USER: {
        draft.currentUser = null
        break
      }

      case REMOVE_SAVED_USER: {
        const { uid } = action.payload
        const idx = draft.usersAccounts.findIndex((ua) => ua.uid === uid)
        if (idx !== -1) {
          draft.usersAccounts.splice(idx, 1)
        }
        break
      }

      default:
    }
  })

export default user
